<template>
  <div v-loading="loading" class="mod-config">
    <div class="topArea">
      <div class="topLeftArea">
        <div class="span">1</div>
        <div class="title">裁切试题</div>
        <hr />
        <div class="span unset" @click="linkTo(2)">2</div>
        <div class="title">标注知识点</div>
        <template v-if="isResMode === 0">
          <hr />
          <div class="span unset" @click="linkTo(3)">3</div>
          <div class="title">匹配变式题</div>
        </template>
      </div>
      <div class="topRightArea">
        <el-button class="btn-style" type="primary" @click="saveExamPaperDivide"
          >完 成</el-button
        >
      </div>
    </div>
    <div class="bottomArea">
      <div class="title">
        <span>{{ examPaper.name || "" }}</span
        ><span>科目：{{ examPaper.subjectId | setName(subjectOptions) }}</span>
      </div>
      <div class="bottomLeftArea fl" @click="editorClick">
        <ckeditor
          v-model="editorData"
          class="examPaperCKEditor"
          type="inline"
          :config="editorConfig"
          :editor-url="editorUrl"
          @ready="editorReady"
        ></ckeditor>
      </div>
      <div class="bottomRightArea fr">
        <div class="questionArea">
          <template v-for="(item, index) in examQuestionData">
            <div
              :key="index"
              class="questionBox"
              @click="clickQuestionBox(item.id)"
            >
              <div class="questionTitle">
                <div class="questionNum">{{ item.qNum }}</div>
                <div class="questionButton">
                  <i
                    class="el-icon-edit"
                    title="编辑题号"
                    @click.stop="clickEditQuestionNum(item.id)"
                  ></i>
                </div>
              </div>
              <div class="questionContent">
                <div class="questionItemTitle">
                  {{ item.isMaterial === 1 ? "材料" : "" }}
                </div>
                <el-select
                  v-model="item.subjectId"
                  class="questionTypeSelect"
                  placeholder="请选择科目"
                  style="width: 80px"
                  @change="changeSubject(item)"
                >
                  <el-option
                    v-for="option in schoolSubjectOptions"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
                <el-select
                  v-model="item.type"
                  class="questionTypeSelect"
                  placeholder="请选择题型"
                  style="width: 90px; margin-left: 5px"
                  @change="changeType(item.epqId)"
                >
                  <el-option
                    v-for="option in examQuestionTypeOptions[item.subjectId]"
                    :key="option.value"
                    :label="option.label"
                    :value="option.value"
                  >
                  </el-option>
                </el-select>
                <div class="questionButtonDiv">
                  <el-button type="text" @click.stop="clickEdit(item.id)"
                    >编辑</el-button
                  ><el-button type="text" @click.stop="clickDelete(item.id)"
                    >删除</el-button
                  >
                </div>
              </div>
              <!--材料题小题-->
              <template v-for="(subItem, subIndex) in item.subQuestions">
                <div :key="subIndex" class="questionContent">
                  <div class="questionItemTitle">
                    <span>小题：{{ subItem.qNum }}</span>
                    <span class="questionTitleEdit">
                      <i
                        class="el-icon-edit"
                        title="编辑题号"
                        @click.stop="clickEditQuestionNum(subItem.id)"
                      ></i>
                    </span>
                  </div>
                  <div class="questionButtonDiv">
                    <el-button type="text" @click.stop="clickEdit(subItem.id)"
                      >编辑</el-button
                    ><el-button
                      type="text"
                      @click.stop="clickDelete(subItem.id)"
                      >删除</el-button
                    >
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </div>
    <!--划题弹窗-->
    <el-dialog
      title="划题"
      :visible.sync="optionDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
    >
      <el-form label-width="80px">
        <el-form-item label-width="160px">
          <el-radio v-model="selectType" :label="0">材料</el-radio>
          <el-radio v-model="selectType" :label="1">试题</el-radio>
        </el-form-item>
        <el-form-item label="题号">
          <el-input v-model="latestQuestionNum"></el-input>
        </el-form-item>
        <el-form-item
          v-if="
            selectType === 0 || (selectType === 1 && latestIsMaterialSub === 0)
          "
          label="科目"
        >
          <el-select
            v-model="latestSubjectId"
            placeholder="请选择科目"
            @change="changeDividePopupSubject"
          >
            <el-option
              v-for="item in schoolSubjectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          v-if="
            selectType === 0 || (selectType === 1 && latestIsMaterialSub === 0)
          "
          label="题型"
        >
          <el-select v-model="latestQuestionType" placeholder="请选择题型">
            <el-option
              v-for="item in examQuestionTypeOptions[latestSubjectId]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="selectType === 1" label="是否为材料题小题">
          <el-checkbox
            v-model="latestIsMaterialSub"
            :true-label="1"
            :false-label="0"
          ></el-checkbox>
        </el-form-item>
        <el-form-item
          v-if="selectType === 1 && latestIsMaterialSub === 1"
          label="该小题所属材料题"
        >
          <el-select
            v-model="latestMaterialSubQuestionParentId"
            placeholder="请选择该小题所属材料题"
          >
            <el-option
              v-for="item in examMaterialQuestionOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="cancelOptionDialog"
          >取 消</el-button
        >
        <el-button class="confirmButton" @click="confirmOptionDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--题号重命名弹窗-->
    <el-dialog
      :visible.sync="qNumDialogVisible"
      :lock-scroll="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="500px"
    >
      <el-form label-width="80px">
        <el-form-item label="题号">
          <el-input v-model="latestQuestionNum"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button class="cancelButton" @click="cancelQNumDialog"
          >取 消</el-button
        >
        <el-button class="confirmButton" @click="confirmQNumDialog"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import $ from "jquery";
import CKEditor from "ckeditor4-vue";
// 服务端各接口需要判断是否有 考试管理相关权限 或 资源中心个人试卷相关权限
import {
  getExamPaperDivideInfo,
  saveExamPaperDivide,
  getExamQuestionTypeByLevel,
} from "@/core/api/exam/exampaper";
import { keyName, setStore, removeStore, getAllStore } from "@/core/util/store";
import { subjectOptions } from "@/core/util/constdata";
import { getSubjectList } from "@/core/util/util";

export default {
  components: { ckeditor: CKEditor.component },
  data() {
    return {
      subjectOptions: subjectOptions,

      isResMode: 0, // 当前是否为资源中心上传试卷后进行切题 0-否 1-是
      examId: 0,
      examLevel: 0,
      examPaper: {},
      examPaperId: 0,
      examSubjectId: 0,
      examPaperHTML: "",
      CKEditorName: "",
      editorData: "",
      editorConfig: {
        title: false,
        language: "zh-cn",
        toolbar: ["MathType"],
        allowedContent: true,
        extraAllowedContent: "div[onselectstart]",
        protectedSource: [/<a[^>]*><\/a>/g],
        readOnly: true,
        on: {
          dataReady: this.dataReady,
        },
      },
      hasDivided: false,
      examQuestionData: [], // 切题数据 [ { epqId: 1, eqId: 1, id: "", qNum: "第1题", type: 1, isMaterial: 0, subQuestions: [] } ]
      optionDialogVisible: false,
      qNumDialogVisible: false,
      selectType: 1, // 划题类型 0-材料 1-试题
      latestQuestionId: "",
      latestQuestionNum: "",
      latestSubjectId: "",
      latestQuestionType: "",
      latestIsMaterialSub: 0,
      latestMaterialSubQuestionParentId: "",
      loading: false,
      schoolSubjectOptions: [],
      examQuestionTypeOptions: {},
      examMaterialQuestionOptions: [], // 当前划出的材料题题干 { value: id, label: qNum }
      removeQuestionIdList: [],
      keyName: keyName, // store.js 下的 keyName ，本地存储前缀
      storePrefix: "examPaperDivide-", // 试卷切题数据的本地存储前缀
      useStore: false,
      editorUrl: "./ckeditor/ckeditor.js",
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  created() {
    if (
      !this.$route.query ||
      Object.keys(this.$route.query).length === 0 ||
      !this.$route.query.id
    ) {
      this.$message.error("没有参数！");
      return;
    }
    if (this.$route.name === "resexampaperdivide") {
      this.isResMode = 1;
    }
    this.loading = true;
    this.initCKEditor();
    window.delQuestionSelection = this.delQuestionSelection;
    // 获取传过来的参数
    this.examPaperId = this.$route.query.id;
    // 获取试卷对象及试卷转换后的HTML
    getExamPaperDivideInfo(this.examPaperId).then((response) => {
      let data = response.data.data;
      this.examId = data.examId;
      this.examLevel = data.level;
      this.examSubjectId = data.subjectId;
      this.examPaper = data.examPaper;
      this.examPaperHTML = data.examPaperHTML;
      if (!this.examPaperHTML || this.examPaperHTML.length === 0) {
        this.$message.error("上传的试卷正在转换处理中！");
        return;
      }
      // 科目默认选择本试卷的科目ID
      this.latestSubjectId = data.subjectId;
      // 获取本校本学段的科目
      let subjectOptions = getSubjectList(this.examLevel);
      for (let i = 0; i < subjectOptions.length; i++) {
        let subjectData = subjectOptions[i];
        this.schoolSubjectOptions.push({
          value: subjectData.value,
          label: subjectData.label,
        });
      }
      // 题型
      this.getQuestionType();
      // CKEditor
      this.initCKEditor();
      if (data.examPaperDivideData.examQuestionData.length > 0) {
        // 试卷已经切题
        this.editorData = this.examPaperHTML;
        this.hasDivided = true;
        this.examQuestionData = data.examPaperDivideData.examQuestionData;
        this.removeQuestionIdList =
          data.examPaperDivideData.removeQuestionIdList;
        for (let i = 0; i < this.examQuestionData.length; i++) {
          let examQuestion = this.examQuestionData[i];
          examQuestion.content = decodeURIComponent(examQuestion.content);
          if (examQuestion.subQuestions) {
            for (let j = 0; j < examQuestion.subQuestions.length; j++) {
              let subExamQuestion = examQuestion.subQuestions[j];
              subExamQuestion.content = decodeURIComponent(
                subExamQuestion.content
              );
            }
          }
        }
      } else {
        // 试卷还未切题
        // 检查浏览器 Web Storage 是否有未保存切题数据
        let examPaperDivideStoreData;
        let storeList = getAllStore({});
        for (let i = 0; i < storeList.length; i++) {
          let store = storeList[i];
          if (
            store.name &&
            store.name.substring(
              0,
              (this.keyName + this.storePrefix).length
            ) ===
              this.keyName + this.storePrefix
          ) {
            let id = Number(
              store.name.substring((this.keyName + this.storePrefix).length)
            );
            if (id === this.examPaper.id) {
              // 找到当前试卷的切题数据
              examPaperDivideStoreData = store.content;
            } else {
              // 移除其他试卷的切题数据
              removeStore({ name: this.storePrefix + id });
            }
          }
        }
        if (examPaperDivideStoreData) {
          this.$confirm(
            "检测到之前未保存的切题数据，是否恢复？<br/>注意：不会恢复知识点，变式题等",
            "提示",
            {
              dangerouslyUseHTMLString: true,
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "info",
            }
          )
            .then(() => {
              this.useStore = true;
              this.examQuestionData = examPaperDivideStoreData.examQuestionData;
              this.editorData = examPaperDivideStoreData.editorData;
            })
            .catch(() => {
              this.editorData = this.examPaperHTML;
            });
        } else {
          this.editorData = this.examPaperHTML;
        }
      }
    });
  },
  mounted() {
    // 设置 questionArea 区域样式
    let screenHeight = $(window).outerHeight(true);
    let headerHeight = $("#kt_header").outerHeight(true);
    let contentPaddingTop = Number(
      $("#kt_content").css("paddingTop").replace("px", "")
    );
    let topAreaHeight = $(".topArea").outerHeight(true);
    let questionAreaHeight =
      screenHeight - headerHeight - contentPaddingTop - topAreaHeight;
    $(".questionArea").css("height", questionAreaHeight + "px");
    $(document).on("scroll.epd", () => {
      let scrollTop = $(document).scrollTop();
      let contentHeight = $("#kt_content").outerHeight(true);
      let bottomCritical = contentHeight - (screenHeight - headerHeight); // 底部即将出现footer的临界值
      if (scrollTop <= contentPaddingTop + topAreaHeight) {
        questionAreaHeight =
          screenHeight -
          headerHeight -
          (contentPaddingTop + topAreaHeight - scrollTop);
        $(".questionArea").css("height", questionAreaHeight + "px");
        $(".questionArea").css("position", "unset");
        $(".questionArea").css("top", "unset");
      } else if (
        scrollTop > contentPaddingTop + topAreaHeight &&
        scrollTop <= bottomCritical
      ) {
        questionAreaHeight = screenHeight - headerHeight;
        $(".questionArea").css("height", questionAreaHeight + "px");
        $(".questionArea").css("position", "fixed");
        $(".questionArea").css("top", headerHeight + "px");
      } else if (scrollTop > bottomCritical) {
        questionAreaHeight =
          screenHeight - headerHeight - (scrollTop - bottomCritical);
        $(".questionArea").css("height", questionAreaHeight + "px");
        $(".questionArea").css("position", "fixed");
        $(".questionArea").css("top", headerHeight + "px");
      }
    });
  },
  destroyed() {
    $(document).off("scroll.epd");
  },
  methods: {
    linkTo(step) {
      if (step === 2 || step === 3) {
        if (!this.hasDivided) {
          this.$message.error("该试卷还未切题！");
          return;
        }
        if (this.examPaper.statusAutoMatch === 0) {
          this.$message.error(
            "服务器正在对已切试题进行自动匹配知识点和变式题的操作！稍后请重试。"
          );
          return;
        }
      }
      if (step === 2) {
        // 标注知识点
        if (this.isResMode === 1) {
          this.$router.push({
            name: "resexamlore",
            query: { id: this.examPaper.id },
          });
        } else {
          this.$router.push({
            name: "examlore",
            query: { id: this.examPaper.id },
          });
        }
      } else if (step === 3) {
        // 锁定变式题
        this.$message.error("不能直接进行后面的步骤！");
      }
    },
    initCKEditor() {
      if (window.CKEDITOR) {
        window.CKEDITOR.dtd.$removeEmpty["span"] = 0;
        window.CKEDITOR.dtd.$removeEmpty["a"] = 0;
        window.CKEDITOR.dtd.$removeEmpty["i"] = 0;
      }
    },
    editorReady(editor) {
      this.initCKEditor();
      this.editorData = this.examPaperHTML;
      this.CKEditorName = editor.name;
      this.loading = false;
    },
    getEditorObj() {
      return window.CKEDITOR.instances[this.CKEditorName];
    },
    dataReady() {
      if (!this.useStore) {
        // 载入服务端自动切题数据
        let queEles = $(".examPaperCKEditor .cke_editable div div.question");
        for (let i = 0; i < queEles.length; i++) {
          let ele = queEles[i];
          $(ele)
            .attr("unselectable", "on")
            .attr("onselectstart", "return false;");
          let id = $(ele).attr("id");
          let qNum = (i + 1).toString();
          if (!this.hasDivided) {
            this.examQuestionData.push({
              id: id,
              qNum: qNum,
              type: "",
              isMaterial: 0,
              subQuestions: [],
            });
          }
        }
        this.removeQuestionIdList.forEach((item) => {
          if (item.length > 0) {
            this.deleteExamQuestion(item);
          }
        });
      } else {
        let queEles = $(".examPaperCKEditor .cke_editable div div.question");
        for (let i = 0; i < queEles.length; i++) {
          let ele = queEles[i];
          $(ele).removeAttr("data-cke-pa-onselectstart");
          $(ele).attr("onselectstart", "return false;");
        }
      }
      $(".examPaperCKEditor .cke_editable img").removeAttr(
        "data-cke-saved-src"
      );
      if (this.useStore) {
        this.useStore = false;
      }
    },
    // 编辑器点击事件
    editorClick(evt) {
      let obj = evt.target || evt.srcElement;
      if ($(obj).hasClass("del") || $(obj).parent().hasClass("del")) {
        // 点击删除按钮
        this.delQuestionSelection(obj);
      } else {
        // 点击其他区域
        this.addQuestionSelection();
      }
    },
    // 手动划题
    addQuestionSelection() {
      let eles = [];
      let startNode, endNode;
      if (window.getSelection) {
        // Chrome, Firefox
        let sel = window.getSelection();
        if (sel.rangeCount) {
          if (!sel.anchorNode || !sel.focusNode) {
            return;
          }
          startNode = sel.anchorNode;
          startNode = this.getParentElement(startNode);
          if (!startNode) {
            return;
          }
          endNode = sel.focusNode;
          endNode = this.getParentElement(endNode);
          if (!endNode) {
            return;
          }
          if (!startNode || !endNode) {
            return;
          }
          if (startNode === endNode) {
            // 划取了一行
            eles.push(startNode);
          } else {
            // 划取了多行
            if (startNode.compareDocumentPosition(endNode) === 4) {
              // DOM结构上，startNode在endNode前
              eles = $(startNode).nextUntil($(endNode));
              eles = eles.toArray();
              eles.unshift(startNode);
              eles.push(endNode);
            } else if (startNode.compareDocumentPosition(endNode) === 2) {
              // DOM结构上，startNode在endNode后
              eles = $(endNode).nextUntil($(startNode));
              eles = eles.toArray();
              eles.unshift(endNode);
              eles.push(startNode);
            }
          }
          // 遍历eles，如果有question类 表示该内容已经属于某道题
          for (let ei = 0; ei < eles.length; ei++) {
            if ($(eles[ei]).hasClass("question")) {
              this.clearSelection();
              this.$message({
                message: "请不要选中已经划好的试题！",
                type: "warning",
                offset: 250,
              });
              return;
            }
          }
        }
      } else if (document.selection) {
        // IE
      }
      // 创建新的<div class="question">元素，并将eles移动到该元素下
      if (eles.length > 0) {
        let queDiv = document.createElement("div");
        this.latestQuestionId = this.guid();
        this.latestQuestionNum = this.calculateQuestionNum();
        $(queDiv).addClass("question");
        $(queDiv).attr("id", this.latestQuestionId);
        $(queDiv).attr("unselectable", "on");
        $(queDiv).attr("onselectstart", "return false;");
        $(startNode).before(queDiv);
        for (let ei = 0; ei < eles.length; ei++) {
          $(eles[ei]).appendTo(queDiv);
        }
        $(queDiv).append(
          '<div class="del"><i class="el-icon-close" title="删除">&nbsp;</i></div>'
        );
        this.optionDialogVisible = true;
        this.buildExamMaterialQuestionOptions();
      }
    },
    getParentElement(ele) {
      let docObj = $(".cke_editable").children("div:eq(0)");
      let foundParent = false;
      while (ele) {
        if ($(ele).parent().is(docObj)) {
          foundParent = true;
          break;
        }
        ele = ele.parentElement;
      }
      if (foundParent) {
        return ele;
      } else {
        this.clearSelection();
        this.$message({
          message: "请正确划题！",
          type: "warning",
          offset: 250,
        });
        return null;
      }
    },
    calculateQuestionNum() {
      let totalCount = 0;
      for (let i = 0; i < this.examQuestionData.length; i++) {
        totalCount++;
        if (this.examQuestionData[i].subQuestions.length > 0) {
          totalCount += this.examQuestionData[i].subQuestions.length;
        }
      }
      totalCount++;
      return totalCount.toString();
    },
    buildExamMaterialQuestionOptions() {
      this.examMaterialQuestionOptions = [];
      for (let i = 0; i < this.examQuestionData.length; i++) {
        if (this.examQuestionData[i].isMaterial === 1) {
          this.examMaterialQuestionOptions.push({
            value: this.examQuestionData[i].id,
            label: this.examQuestionData[i].qNum,
          });
        }
      }
    },
    // 删除划题
    delQuestionSelection(obj) {
      let queDiv;
      if ($(obj).hasClass("del")) {
        queDiv = $(obj).parent();
      } else if ($(obj).parent().hasClass("del")) {
        queDiv = $(obj).parent().parent();
      }
      let qId = $(queDiv).attr("id");
      this.deleteExamQuestion(qId);
    },
    // 清除鼠标选中（涂蓝）的内容
    clearSelection() {
      if (window.getSelection) {
        if (window.getSelection().empty) {
          // Chrome
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          // Firefox
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        // IE
        document.selection.empty();
      }
    },
    guid() {
      return (
        this.S4() +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        "-" +
        this.S4() +
        this.S4() +
        this.S4()
      );
    },
    S4() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    },
    clickQuestionBox(qId) {
      $(".examPaperCKEditor .cke_editable div div.question").removeClass(
        "question-selected"
      );
      $("#" + qId).addClass("question-selected");
    },
    clickEditQuestionNum(id) {
      this.latestQuestionId = id;
      this.latestQuestionNum = this.getQNumById(id);
      this.qNumDialogVisible = true;
    },
    getQNumById(id) {
      let qNum = "";
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.id === id) {
          qNum = examQuestion.qNum;
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.id === id) {
              qNum = subExamQuestion.qNum;
              break cycle;
            }
          }
        }
      }
      return qNum;
    },
    changeSubject(item) {
      item.type = "";
      if (
        this.examQuestionTypeOptions[item.subjectId] &&
        this.examQuestionTypeOptions[item.subjectId].length > 0
      ) {
        // 默认选中该科目第一个题型
        item.type = this.examQuestionTypeOptions[item.subjectId][0].value;
      }
      let epqId = item.epqId;
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.epqId === epqId) {
          examQuestion.epqId = 0;
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.epqId === epqId) {
              subExamQuestion.epqId = 0;
              break cycle;
            }
          }
        }
      }
      this.setStore();
    },
    changeType(epqId) {
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.epqId === epqId) {
          examQuestion.epqId = 0;
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.epqId === epqId) {
              subExamQuestion.epqId = 0;
              break cycle;
            }
          }
        }
      }
      this.setStore();
    },
    clickEdit(qId) {
      $(".examPaperCKEditor .cke_editable div div.question").removeClass(
        "question-selected"
      );
      $("#" + qId).addClass("question-selected");
      $("html").scrollTop($("#" + qId)[0].offsetTop);
    },
    changeDividePopupSubject() {
      this.latestQuestionType = "";
      if (
        this.examQuestionTypeOptions[this.latestSubjectId] &&
        this.examQuestionTypeOptions[this.latestSubjectId].length > 0
      ) {
        // 默认选中该科目第一个题型
        this.latestQuestionType =
          this.examQuestionTypeOptions[this.latestSubjectId][0].value;
      }
    },
    getQuestionType() {
      this.latestQuestionType = "";
      this.examQuestionTypeOptions = {};
      getExamQuestionTypeByLevel({ level: this.examLevel }).then((response) => {
        let resQuestionTypes = response.data.data;
        for (let i = 0; i < resQuestionTypes.length; i++) {
          let type = resQuestionTypes[i];
          let subjectId = type.subjectId;
          let subArr = this.examQuestionTypeOptions[subjectId];
          if (!subArr) {
            subArr = [];
          }
          if (type.parentId === 0) {
            // 主题型
            subArr.push({
              value: type.id,
              label: type.name,
            });
            // 子题型
            for (let j = 0; j < resQuestionTypes.length; j++) {
              let subType = resQuestionTypes[j];
              if (subType.parentId === type.id) {
                subArr.push({
                  value: subType.id,
                  label: type.name + " - " + subType.name,
                });
              }
            }
          }
          this.examQuestionTypeOptions[subjectId] = subArr;
        }
        this.changeDividePopupSubject();
      });
    },
    clickDelete(qId) {
      this.deleteExamQuestion(qId);
    },
    deleteExamQuestion(qId) {
      this.deleteExamQuestionFromCKEditor(qId);
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.id === qId) {
          if (examQuestion.subQuestions) {
            for (let j = 0; j < examQuestion.subQuestions.length; j++) {
              let subExamQuestion = examQuestion.subQuestions[j];
              this.deleteExamQuestionFromCKEditor(subExamQuestion.id);
            }
          }
          this.examQuestionData.splice(i, 1);
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.id === qId) {
              examQuestion.subQuestions.splice(j, 1);
              break cycle;
            }
          }
        }
      }
      this.setStore();
    },
    deleteExamQuestionFromCKEditor(qId) {
      let queDiv = $("#" + qId);
      let eles = $(queDiv).children();
      for (let ei = 0; ei < eles.length; ei++) {
        if (!$(eles[ei]).hasClass("del")) {
          $(eles[ei]).insertBefore(queDiv);
        }
      }
      $(queDiv).remove();
    },
    cancelOptionDialog() {
      this.deleteExamQuestion(this.latestQuestionId);
      this.clearLatestValues();
      this.optionDialogVisible = false;
    },
    confirmOptionDialog() {
      if (!this.latestQuestionNum || this.latestQuestionNum.length === 0) {
        this.$message.error("请输入题号！");
        return;
      }
      if (this.isThisQuestionNumUsed(this.latestQuestionNum)) {
        this.$message.error("该题号已使用，请更换！");
        return;
      }
      if (
        (this.selectType === 0 ||
          (this.selectType === 1 && this.latestIsMaterialSub === 0)) &&
        (!this.latestSubjectId || this.latestSubjectId.length === 0)
      ) {
        this.$message.error("请选择科目！");
        return;
      }
      if (
        (this.selectType === 0 ||
          (this.selectType === 1 && this.latestIsMaterialSub === 0)) &&
        (!this.latestQuestionType || this.latestQuestionType.length === 0)
      ) {
        this.$message.error("请选择题型！");
        return;
      }
      if (this.selectType === 1 && this.latestIsMaterialSub === 1) {
        // 材料题小题
        if (
          !this.latestMaterialSubQuestionParentId ||
          this.latestMaterialSubQuestionParentId.length === 0
        ) {
          this.$message.error("请选择该小题所属材料题！");
          return;
        }
        for (let i = 0; i < this.examQuestionData.length; i++) {
          if (
            this.examQuestionData[i].id ===
            this.latestMaterialSubQuestionParentId
          ) {
            this.examQuestionData[i].subQuestions.push({
              epqId: 0,
              id: this.latestQuestionId,
              qNum: this.latestQuestionNum,
              subjectId: this.latestSubjectId,
              type: 0,
              isMaterial: 1,
            });
            break;
          }
        }
      } else {
        // 材料题材料 或 普通试题
        this.examQuestionData.push({
          epqId: 0,
          id: this.latestQuestionId,
          qNum: this.latestQuestionNum,
          subjectId: this.latestSubjectId,
          type: this.latestQuestionType,
          isMaterial: this.selectType === 0 ? 1 : 0,
          subQuestions: [],
        });
      }
      this.clearLatestValues();
      this.optionDialogVisible = false;
      this.setStore();
    },
    cancelQNumDialog() {
      this.clearLatestValues();
      this.qNumDialogVisible = false;
    },
    confirmQNumDialog() {
      if (!this.latestQuestionNum || this.latestQuestionNum.length === 0) {
        this.$message.error("请输入题号！");
        return;
      }
      if (
        this.isThisQuestionNumUsed(
          this.latestQuestionNum,
          this.latestQuestionId
        )
      ) {
        this.$message.error("该题号已使用，请更换！");
        return;
      }
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.id === this.latestQuestionId) {
          examQuestion.epqId = 0;
          examQuestion.qNum = this.latestQuestionNum;
          break;
        }
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let subExamQuestion = examQuestion.subQuestions[j];
            if (subExamQuestion.id === this.latestQuestionId) {
              subExamQuestion.epqId = 0;
              subExamQuestion.qNum = this.latestQuestionNum;
              break cycle;
            }
          }
        }
      }
      this.clearLatestValues();
      this.qNumDialogVisible = false;
      this.setStore();
    },
    isThisQuestionNumUsed(qNum, qId) {
      let has = false;
      cycle: for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (examQuestion.qNum === qNum && examQuestion.id !== qId) {
          has = true;
          break;
        }
        if (examQuestion.subQuestions.length > 0) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            if (
              examQuestion.subQuestions[j].qNum === qNum &&
              examQuestion.subQuestions[j].id !== qId
            ) {
              has = true;
              break cycle;
            }
          }
        }
      }
      return has;
    },
    clearLatestValues() {
      this.selectType = 1;
      this.latestQuestionId = "";
      this.latestQuestionNum = "";
      this.latestQuestionType = "";
      this.latestIsMaterialSub = 0;
      this.latestMaterialSubQuestionParentId = "";
    },
    setStore() {
      setStore({
        name: this.storePrefix + this.examPaper.id,
        content: {
          examQuestionData: this.examQuestionData,
          editorData: $(".examPaperCKEditor .cke_editable").html(),
        },
      });
    },
    saveExamPaperDivide() {
      if (this.examQuestionData.length === 0) {
        this.$message.error("请划取试题！");
        return;
      }
      let examQuestions = [];
      let materialIndex = 1;
      for (let i = 0; i < this.examQuestionData.length; i++) {
        let examQuestion = this.examQuestionData[i];
        if (!examQuestion.subjectId || examQuestion.subjectId.length === 0) {
          this.$message.error("有划取的试题未选择科目，请选择！");
          return;
        }
        if (!examQuestion.type || examQuestion.type.length === 0) {
          this.$message.error("有划取的试题未选择题型，请选择！");
          return;
        }
        let examQuestionVO = {};
        $("#" + examQuestion.id + " .del").remove();
        let html = $("#" + examQuestion.id).html();
        if (!html) {
          this.$message.error(
            "试题 " + examQuestion.qNum + " 被清除了左侧区域，请选择切题！"
          );
          return;
        }
        examQuestionVO.question = encodeURIComponent(html);
        examQuestionVO.subjectId = examQuestion.subjectId;
        examQuestionVO.type = examQuestion.type;
        examQuestionVO.examPaperNum = examQuestion.qNum;
        examQuestionVO.epqId = examQuestion.epqId;
        if (examQuestion.isMaterial === 1) {
          if (
            examQuestion.subQuestions &&
            examQuestion.subQuestions.length === 0
          ) {
            this.$message.error("试题 " + examQuestion.qNum + " 未划取小题！");
            return;
          }
          examQuestionVO.materialIndex = materialIndex;
          examQuestionVO.parentMaterialIndex = 0;
        } else {
          examQuestionVO.materialIndex = 0;
          examQuestionVO.parentMaterialIndex = 0;
        }
        examQuestions.push(examQuestionVO);
        if (examQuestion.subQuestions) {
          for (let j = 0; j < examQuestion.subQuestions.length; j++) {
            let examQuestionSub = examQuestion.subQuestions[j];
            let examQuestionVOSub = {};
            $("#" + examQuestionSub.id + " .del").remove();
            let htmlSub = $("#" + examQuestionSub.id).html();
            if (!htmlSub) {
              this.$message.error(
                "试题 " +
                  examQuestionSub.qNum +
                  " 被清除了左侧区域，请选择切题！"
              );
              return;
            }
            examQuestionVOSub.question = encodeURIComponent(htmlSub);
            examQuestionVOSub.subjectId = examQuestion.subjectId; // 材料题子题的科目直接使用材料的科目
            examQuestionVOSub.type = examQuestion.type; // 材料题子题的题型直接使用材料的题型
            examQuestionVOSub.examPaperNum = examQuestionSub.qNum;
            examQuestionVOSub.epqId = examQuestionSub.epqId;
            if (examQuestion.isMaterial === 1) {
              examQuestionVOSub.materialIndex = 0;
              examQuestionVOSub.parentMaterialIndex = materialIndex;
            } else {
              examQuestionVOSub.materialIndex = 0;
              examQuestionVOSub.parentMaterialIndex = 0;
            }
            examQuestions.push(examQuestionVOSub);
          }
        }
        materialIndex++;
      }
      let data = {};
      data.examId = this.examId;
      data.examLevel = this.examLevel;
      data.examSubjectId = this.examSubjectId;
      data.examPaperId = this.examPaper.id;
      data.examPaperDivideQuestionVOs = examQuestions;
      this.loading = true;
      saveExamPaperDivide(data)
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          this.loading = false;
          // 服务端会对每道题匹配变式训练题和知识点，耗时较长。故不直接进入下一步的页面。
          // if (this.isResMode === 1) {
          //   this.$router.push({
          //     name: "resexamlore",
          //     query: { id: this.examPaper.id }
          //   });
          // } else {
          //   this.$router.push({
          //     name: "examlore",
          //     query: { id: this.examPaper.id }
          //   });
          // }
          if (this.isResMode === 1) {
            // 返回上一页
            this.$router.go(-1);
          } else {
            // 返回试题管理页面
            this.$router.push({
              name: "exampaper",
              query: {
                id: this.examId, // 考试ID
                subId: this.examSubjectId, // 科目ID
              },
            });
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.loading = false;
          this.$message.error("保存失败！");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/pages/exam/ExamPaperDivide.scss";
</style>

<style lang="scss">
.mod-config
  .bottomArea
  .bottomLeftArea
  .examPaperCKEditor
  .exam-paper-html-root-div {
  font-size: 14px;
  .question {
    position: relative;
    background-color: #fff9c4;
    margin: 8px 0px;
    .del {
      display: none;
      position: absolute;
      left: 722px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      i {
        color: #ff0000;
        font-size: 24px;
      }
    }
  }
  .question:hover {
    .del {
      display: block;
    }
  }
  .question-selected {
    background-color: #fff176;
  }
}
</style>
